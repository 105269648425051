import './index.sass'
import * as Axios from 'axios'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const Order = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [comment, setComment] = useState()
  const [error, setError] = useState([])
  const history = useHistory()

  const sendEmail = () => {
    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegexp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
    let errors = []
    if (!name) {
      errors.push('name')
    }
    if (!emailRegexp.test(email) && !phoneRegexp.test(email)) {
      console.log(emailRegexp.test(email))
      console.log(phoneRegexp.test(email))
      errors.push('email')
    }
    if (!comment) {
      errors.push('comment')
    }
    setError(errors)
    if (errors.length === 0) {
      console.log('ping')
      Axios.post(`/api/send-email?email=${email}&name=${name}&comment=${comment}`).then(res => {
        console.log(res)
        console.log(res.status)
        if (res.status === 200 ) {
          setName()
          setEmail()
          setComment()
          history.push('/thanks')
        }
      })
    }   
  }

  return(
    <div>
      <div className='section--header_wrapper orange noPadding'>
        <h1 className='section--header orange'>Обратная связь</h1>
      </div>
      <div className='input-wrapper'>
        <span>Имя</span>
        <input className={error.indexOf('name') !== -1 ? 'order-input inp-err' : 'order-input'} value={name} onChange={(e)=>setName(e.target.value)}/>
        {error.indexOf('na,e') !== -1 && <span className='or-error'>Это поле обязательное</span>}
      </div>
      <div className='input-wrapper'>
        <span>Email или телефон</span>
        <input className={error.indexOf('email') !== -1 ? 'order-input inp-err' : 'order-input'} value={email} onChange={(e)=>setEmail(e.target.value)}/>
        {error.indexOf('email') !== -1 && <span className='or-error'>Это поле обязательное, пожалуйста укажите валидный эмейэ или номер телефона</span>}
      </div>
      <div className='input-wrapper'>
        <span>Коментарий</span>
        <textarea className={error.indexOf('comment') !== -1 ? 'order-input order-textarea inp-err': 'order-input order-textarea'} value={comment} onChange={(e)=>setComment(e.target.value)}/>
        {error.indexOf('comment') !== -1 && <span className='or-error'>Это поле обязательное</span>}
      </div>
      <div className='input-wrapper'>
         <button className='send-button' onClick={sendEmail}>Отправить</button>
      </div>
    </div>
  )
}

export default Order