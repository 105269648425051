import './index.sass';
import TopSection from './TopSection'
import AdvantageSection from './AdvantageSection'
import FormatsSection from './FormatsSection'
import ServicesSection from './ServicesSection'
import PCGallery from '../../components/Gallery';
import PartnerSection from './PartnersSection';


const Main = (props) => {
  const { refs } = props
  return (
    <div className="App">
      <TopSection />
      <AdvantageSection />
      <FormatsSection refer={refs.formats}/>
      <ServicesSection refer={refs.services}/>
      <PCGallery refer={refs.gallery}/>
      <PartnerSection refer={refs.parthners}/>
    </div>
  );
}

export default Main;
