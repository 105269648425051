import './index.sass';
import logoSmall from '../../../public/logo-small.png'


const advantageCards = [
  { label: 'Отменное качество', text: 'Мы берём на себя организацию и обслуживание Вашего мероприятия на всех его стадиях проведения.' },
  { label: 'Большой выбор', text: 'Разнообразие блюд различной кухни Мира не оставит равнодушным даже самого изысканного гурмана.' },
  { label: 'Индивидуальный подход', text: 'Разработка персонального меню с учётом всех пожеланий под каждое Ваше мероприятие.' },
  { label: 'Отменное качество', text: 'Мы хотим, чтобы после проведения нашего мероприятия у Вас остались только хорошие воспоминания.' },
]

const AdvantageSection = () => {
  return (
    <div className='section section-withoutBackground  noPadding'>
      <div className='section--header_wrapper orange'>
        <h2 className='section--header orange'>НАШИ ПРЕИМУЩЕСТВА</h2>
      </div>
      <div className='plus-holder'>
        {advantageCards.map(card => <div className='plus-holder--card'>
          <h3 className='plus-holder--card__header'>{card.label}</h3>
          <span className='plus-holder--card__description'>{card.text}</span>
        </div>)}
      </div>
      <div className='logo-small-wrapper'>
        <img className='logo-small' src={logoSmall} alt='logo-small' />
      </div>
    </div>
  );
}

export default AdvantageSection;
