import { useHistory } from 'react-router-dom';
import './index.sass';

const TopSection = () => {
  const history = useHistory()
  return (
    <div className='section section__order'>
      <div className='section--header_wrapper section--header_wrapper__order'>
        <h1 className='section--header'>Мероприятия на любое количество персон</h1>
      </div>
      <div className='section--row'>
        <div className='order-button'>
          <button className='order-button--button' onClick={()=>history.push('/order')}>ЗАКАЗАТЬ</button>
        </div>
        <div className='order-contacts'>
          <a href='mailto:Prime.catering.eat@gmail.com' className='order-contacts--contact'>Prime.catering.eat@gmail.com</a>
          <a href='tel:+380688067306' className='order-contacts--contact'>+38 (068) 806-7306</a>
        </div>
      </div>
    </div>
  );
}

export default TopSection;
