import business from '../../../public/business.jpg'
import corppit from '../../../public/corppit.jpg'
import priv from '../../../public/public.jpg'
import corporate from '../../../public/corporate.jpg'
import { NavLink } from 'react-router-dom'

const services = [
  { title: "Бизнес мероприятия", list: ["Конференции", "Бизнес встречи"], image: business, href: '/bussiness' },
  { title: "Корпоративные мероприятия", list: ["Корпоративы", "Тимбилдинги"], image: corporate, href: '/corporate' },
  {
    title: "Частные мероприятия",
    list: ["Дни Рождения", "Выпускные", "Свадьбы", "Юбилеи", "Детские мероприятия"],
    image: priv,
    href: '/private'
  },
  { title: "Корпоративное питание", list: ["Доставим еду вам в офис"], image: corppit, href:'/corporativnoe-pitanie' },
]

const ServiceSection = (props) => {
  return (
      <div ref={props.refer} className='section section-withoutBackground noPadding'>
        <div className='section--header_wrapper orange'>
          <h2 className='section--header orange'>УСЛУГИ</h2>
        </div>
        <div className='service-wrapper'>
          {services.map(service => <div className='service'>
            <div className='service-description'>
              <h3 className='service-header'>{service.title}</h3>
              <div className='ser-dsc'>{service.list.map(item => <div>{item}</div>)}</div>
              <NavLink className='cards-holder--card--more more-service' to={service.href}>Подробнее</NavLink>
            </div>
            <div className='service-img--wrapper'>
              <div className='service-img--wrapper-sec'>
                <img src={service.image} className='service-img' alt='service-img' />
              </div>
            </div>
          </div>)}
        </div>
      </div>
  );
}

export default ServiceSection;
