import './index.sass';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Gallery from 'react-photo-gallery';
import _1 from '../../public/gallery/1.jpg'
import _2 from '../../public/gallery/2.jpg'
import _3 from '../../public/gallery/3.jpg'
import _4 from '../../public/gallery/4.jpg'
import _5 from '../../public/gallery/5.jpg'
import _6 from '../../public/gallery/6.jpg'
import _7 from '../../public/gallery/7.jpg'
import _8 from '../../public/gallery/8.JPG'
import _9 from '../../public/gallery/9.JPG'
import _10 from '../../public/gallery/10.jpg'
import _11 from '../../public/gallery/11.jpg'
import { useState } from 'react';

const gallery = [
  { src:  _1, width: 2, height: 3, alt: 'galleryPhoto' },
  { src:  _2,  width: 4, height: 3, alt: 'galleryPhoto' },
  { src:  _3, width: 3, height: 2, alt: 'galleryPhoto' },
  { src:  _4, width: 2.6, height: 4, alt: 'galleryPhoto' },
  { src:  _5, width: 3, height: 2, alt: 'galleryPhoto' },
  { src:  _6, width: 2, height: 1, alt: 'galleryPhoto' },
  { src:  _7,  width: 3, height: 2, alt: 'galleryPhoto' },
  { src:  _8, width: 5, height: 3, alt: 'galleryPhoto' },
  { src:  _9, width: 5, height: 3, alt: 'galleryPhoto' },
  { src:  _10, width: 64, height: 96, alt: 'galleryPhoto' },
  { src:  _11, width: 12, height: 8, alt: 'galleryPhoto' },
]

const PCGallery = (props) => {
  const [photosLength, setLength] = useState(6)
  return (
      <div ref={props.refer} className='section section-withoutBackground noPadding'>
        <div className='section--header_wrapper orange'>
          <h2 className='section--header orange'>ГАЛЕРЕЯ</h2>
        </div>
        <div className='carouselWrapper'>
          
        </div>
        <div className='gallery'>
          <Gallery photos={gallery.slice(0, photosLength)}/>
          { photosLength < gallery.length ? 
            <button className='more-Btn' onClick={()=>setLength(photosLength + 6)}>Ещё...</button>:
           null }
        </div>
      </div>
  );
}

export default PCGallery;
