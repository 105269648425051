import './index.sass'
import { FaCheck } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
const Thanks = () => {
  const history = useHistory()
  return(
    <div className='th-root'>
      <h1>Спасибо что выбрали нас</h1>
      <FaCheck className='chckIco'/>
      <h4>Наш менеджер свяжется с вами в ближайшее время</h4>
      <button className='btn' onClick={()=>history.push('/')}>Вернуться на главную</button>
    </div>
  )   
}

export default Thanks