import './index.sass';

const Footer = () => {
  return (
    <footer className='footer'>
      <a href='tel:+380688067306' className='white'>+38 (068) 806-7306</a>
      <a href='mailto:Prime.catering.eat@gmail.com' className='white'>Prime.catering.eat@gmail.com</a>
      <span className='coprt'>© "Prime Catering" 2021</span>
    </footer>
  );
}

export default Footer;
