import './index.sass'
import _1 from '../../../public/logos/1.jpg'
import _2 from '../../../public/logos/2.png'
import _3 from '../../../public/logos/3.png'
import _4 from '../../../public/logos/4.png'
import _5 from '../../../public/logos/5.jpg'
import _6 from '../../../public/logos/6.jpg'
import _7 from '../../../public/logos/7.png'
import _8 from '../../../public/logos/8.png'
import _9 from '../../../public/logos/9.jpg'
import _10 from '../../../public/logos/10.png'
import _11 from '../../../public/logos/11.jpg'

const images = [ _1, _4, _5, _2, _3, _6, _7, _8, _9, _10, _11 ]

const PartnerSection = (props) => {
  return(
    <div ref={props.refer} className='section partner-section section-withoutBackground noPadding'>
      <div className='section--header_wrapper orange'>
        <h2 className='section--header orange'>НАШИ ПАРТНЕРЫ</h2>
      </div>
      <div className='pictures-row'>
        {images.map(img => <img src={img} alt='partnerLogo' className='partner-logo'/> )}
      </div>
    </div>
  )
}

export default PartnerSection