import './App.sass'
import Main from './pages/main'
import Content from './components/Content'
import Header from './components/Header'
import Footer from './components/Footer'
import { useRef } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Order from './pages/order'
import cofe from './public/cofe.jpg'
import caterignFurshet from './public/catering-furshet.jpg'
import cateringBanket from './public/cateing-banket.jpg'
import bbq from './public/bbq.jpg'
import bussiness from './public/business.jpg'
import corporate from './public/corporate.jpg'
import priv from './public/public.jpg'
import corppit from './public/corppit.jpg'
import Thanks from './pages/thanks'
import ScrollToTop from './hoc/ScrollToTop'

const cbText = <>
  <p>Кофе-брейк длится 30-60 минут. Это короткое, но очень важное время.</p>

  <p>Кофе-пауза не рассчитана на рассадку гостей. Еда и напитки расставляются на фуршетной линии. В зависимости от длительности конференции, выставки или семинара может проводиться несколько кофе-брейков. Первый – при встрече гостей, остальные - во время перерывов и по окончанию мероприятия.
    Благодаря опыту нашей кейтеринговой компании и собственным наработкам, наши работники смогут точно рассчитать все необходимые объемы закусок и напитков, чтобы  клиент не переплачивал за лишние порции. В итоге каждый гость мероприятия сможет всё попробовать, но при этом оформление стола будет лаконичным и аккуратным.
    Кофе-брейк предполагает наличие бара с безалкогольными напитками, чая и кофе, линии для расстановки закусок, коктейльных столов.
    Меню кофе-брейка может включать холодные мини-закуски, выпечку, сэндвичи, десерты и фрукты.
    Кейтеринг "Prime" организует и проведет кофе-брейк правильно и недорого!</p>
</>

const banText = <>
  <p>Торжественное мероприятие, приуроченное определённому событию, сопровождается банкетом.
    Это позволяет гостям отдохнуть и расслабиться. Но, современная организация банкетов не только ограничивается ресторанами.
    Самыми оригинальными становятся выездные банкеты, что может организовать наша кейтеринговая компания в
    пределах Киева, а также Киевской области.
    Подходящий формат для проведения массовых мероприятий: корпоративных праздников, конференций, выставок, концертов,
    фестивалей и свадеб. Специалистами предлагаются совершенно новые локации для проведения банкета. Это может
    быть терраса у красивого и живописного водоёма, яхта, загородный дом, и открытое поле для нас не проблема! Мы тщательно
    прорабатываем особенности и пожелания заказчиков:</p>
  <ul className='text-list'>
    <li>Cоответствующее оформление столов.</li>
    <li>Тщательно разработанное меню.</li>
    <li>Обеспечение необходимым инвентарем.</li>
    <li>Компетентный персонал.</li>
    <li>Учет санитарных норм и других нюансов, связанных с организацией процесса питания на природе.</li>
  </ul>
</>

const furshetText = <>
  <p>Организация фуршетов – современные решения обслуживания мероприятий.  Закажите фуршет, а лучше доверьте организацию фуршета
    нашей компании, которая уже не первый год обслуживает различные тематические мероприятия и грандиозные корпоративные торжества,
    семейные праздники, бизнес встречи, выставки, призентации,  которые требуют подготовку фуршетного стола.
    Вам нет необходимости придумывать, а затем контролировать исполнение сценария праздника. Доверив нашим сотрудникам выездной
    фуршет в Киеве и Киевской области, вам остается только спокойно наблюдать за процессом проведения мероприятия, и у нас нет
    сомнений, что праздник вам понравится. Организация фуршета включает в себя не только обеспечение едой и напитками его
    участников. Нам доверяют поиск места для проведения мероприятия и оформление мероприятия под формат праздника.
    Если вы желаете заказать кейтеринг фуршет с ресторанным меню, тогда наша компания готова предоставить
    для вас качественное обслуживание.
  </p>
</>

const bbqText = <>
  <p>Аппетитные колбаски, сочные стейки, печеные овощи и морепродукты ― их притягательный аромат порадует ценителей
    гриль-меню, приготовленных на углях или открытом огне. Закажите кейтеринг барбекю у нас, и организация вечеринки на свежем воздухе
    превратится для вас в приятный отдых, лишенный любых хлопот!
    Наш кейтеринг барбекю ― это настоящий выездной ресторан на природе. </p>
  <p>
    Мы с радостью подберем подходящую площадку, возьмем на себя заботы по доставке оборудования и
    аксессуаров разработаем меню, идеально подходящее именно к вашему празднику. Все блюда готовятся на месте из отборных
    ингредиентов безупречного качества. Каждое мероприятие нашей службы кейтеринга обслуживают профессиональные повара,
    которые используют для приготовления профессиональное гриль-оборудование. По окончании мероприятия мы проведем полную
    уборку территории.
  </p>
</>
const bussinessTxt = <>
  <p>Cегодня уже ни у кого не возникает сомнения о важности мероприятий для развития бизнес стратегии.
    Произвести впечатление на потенциальных партнеров и клиентов, выразить заботу об участниках мероприятия,
    воодушевить персонал, помогут услуги выездного кейтеринга. Проведение выездных фуршетов и бизнес-ланчей –
    это не только организация питания, но и средство создания имиджа процветающей компании. Поэтому доверить
    кейтеринг для Вашего бизнес-мероприятия стоит только профессионалам.
    <br />Для сопровождения деловых мероприятий подойдет формат кофе-брейка и бизнес-ланча.
    К открытию или завершению важных мероприятий стоит заказать более сложный кейтеринг,
    проведя гала-ужин, фуршет или банкет.</p>
</>
const corporateTxt = <>
  <p>
    Это может быть новогодний корпоратив, юбилей создания компании, открытие нового офиса или магазина,  тематические праздники,
    конференции или призентации, выставки. Так же как и тренинги, тимбилдинги и другие типы совместного полезного времяпрепровождения.
    Помимо чисто практических целей профессионального обмена, деловые мероприятия служат еще и хорошим подспорьем в деле
    сближения с партнерами и завязываний знакомств. В деловой, но, в то же время, непринужденной обстановке, сотрудники,
    компаньоны, как будущие, так и потенциальные, вступают в непосредственное общение. Это служит созданию благоприятного климата
    в среде, наращиваю партнерских и деловых отношений, что обязательно, в конечном итоге, приводит к плодотворному сотрудничеству.
    Кейтеринг "Prime" рад предложить вам всё самое необходимое для организации делового мероприятия: подбор площадки,
    техническое оснащение, меню фуршета или банкета. Всё это в совокупности с грамотной организацией процесса создадут
    непередаваемую атмосферу поистине важного события. Ощущение торжественности и деловой встречи создадут уникальную почву
    для будущих успехов, новых контактов и отличных перспектив.
  </p>
</>
const privateTxt = <>
  <p>
    Организация и проведение частного мероприятия – это гарантия того, что все моменты и детали торжества
    будут спланированы и продуманы до мелочей. Заказать организацию Вашего мероприятия  в нашей компании "Prime"
    кейтеринг – значит подарить себе и своим дорогим гостям отдых и незабываемую встречу. Мы согласуем с вами все детали,
    учтем все пожелания, предложим оригинальные варианты меню и проведение праздника по индивидуальному сценарию.
    Мы гарантируем удивительный празник вне зависимости от места и времени проведения.
  </p>
</>

const corppitTxt = <>
  <p>
    Классические салаты, густые и легкие супы, аппетитные горячие блюда и разнообразные гарниры мы готовим по любимым
    рецептам в лучших традициях домашней кухни и доставляем к началу обеденного перерыва. Не будьте заложниками бизнес-ланчей
    и фастфуда! Мы предлагаем классические, диетические, вегетарианские, постные блюда собственного производства.
    Мы отвечаем за качество каждого блюда, поэтому они всегда свежие и по-домашнему вкусные.
    Вас всегда ждут наваристые супы, хрустящие салаты, гарниры, напитки и выпечка с доставкой в офис в Киеве!
  </p>
</>

function App() {
  const formats = useRef()
  const services = useRef()
  const gallery = useRef()
  const parthners = useRef()
  const refs = { formats, services, gallery, parthners }
  return (
    <div className="App">
      <Header refs={refs} />
        <ScrollToTop>
        <Switch>
          <Route path='/' exact >
            <Main refs={refs} />
          </Route>
          <Route path='/thanks' exact >
            <Thanks />
          </Route>
          <Route path='/order' exact >
            <Order />
          </Route>
          <Route path='/coffee-break'>
            <Content
              img={cofe}
              title='КОФЕ БРЕЙК'
              textSecondary={<>
                Правильная организация <b>кофе-брейка</b> положительно влияет на результат деловых встреч и переговоров.
                Кофе-брейк помогает участникам деликатно сменить обстановку, отдохнуть и расслабиться в уютной и
                непринужденной атмосфере.</>}
              textMain={cbText} />
          </Route>
          <Route path='/banket'>
            <Content
              img={cateringBanket}
              title='БАНКЕТ'
              textSecondary={<>
                <b>Банкет</b> - это отличная возможность торжественно отметить какое-либо знаменательное событие и создать о нем
                хорошие воспоминания, наслаждаясь временем, проведенным в кругу друзей и близких людей.
                Выездной  банкет один из самых популярных форматов ресторанного обсужования.</>}
              textMain={banText} />
          </Route>
          <Route path='/furshet'>
            <Content
              img={caterignFurshet}
              title='ФУРШЕТ'
              textSecondary={<>
                <b>Фуршет</b> – это непринужденная дружеская атмосфера и легкие закуски. Он подойдет не только для вечеринок,
                лекций и бизнес-встреч, но и для традиционно «застольных» праздников. Если вы хотите сделать акцент на живом общении,
                если не все гости знакомы друг с другом, фуршет — это то, что нужно!</>}
              textMain={furshetText} />
          </Route>
          <Route path='/bbq'>
            <Content
              img={bbq}
              title='БАРБЕКЮ'
              textSecondary={<>
                <b>Барбекю (BBQ)</b> ― идеальный формат для совместных выездов за город, пляжных вечеринок,
                корпоративных мероприятий и теплых дружеских встреч на природе. </>}
              textMain={bbqText} />
          </Route>
          <Route path='/bussiness'>
            <Content
              img={bussiness}
              title='Бизнес мероприяти'
              textSecondary={<>
                <b>Бизнес мероприятия — конференции</b>, презентации, круглые столы, форумы, семинары, встречи, которые
                по составу, длительности, географическому охвату превосходят классические офисные совещания. </>}
              textMain={bussinessTxt} />
          </Route>
          <Route path='/corporate'>
            <Content
              img={corporate}
              title='Копроративные Мероприятия'
              textSecondary={<>
                Каждая компания, независимо от количества сотрудников и выделяемого бюджета, проводит
                <b>корпоративные мероприятия</b> в течение года. </>}
              textMain={corporateTxt} />
          </Route>
          <Route path='/private'>
            <Content
              img={priv}
              title='Частные Мероприятия'
              textSecondary={<>
                Если у вас приближается событие, будь то свадьба, юбилей, годовщина свадьбы, день рождения или просто хотите
                собрать всех своих близких и друзей, то обратившись в наш кейтеринг в Киеве и Киевской области вы получите
                организацию и проведение своего частного мероприятия на высшем уровне.</>}
              textMain={privateTxt} />
          </Route>
          <Route path='/corporativnoe-pitanie'>
            <Content
              img={corppit}
              title='Копроративное Питание'
              textSecondary={<>
                В современном мегаполисе нет ничего важнее вкусного, сытного и полезного обеда в течение рабочего дня.
                Именно поэтому мы разработали специальное меню комплексных обедов с доставкой по Киеву прямо в офис.</>}
              textMain={corppitTxt} />
          </Route>
          <Redirect to='/' />
        </Switch>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
