import './index.sass'
import { FaArrowLeft } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const Content = (props) => {
  const { title, textSecondary, textMain } = props
  return (
    <div>
        <div className='cc-with-bck' >
            <div className='cc-background' style={{ backgroundImage: `url(${props.img})` }} />
            <div className='cc-back-content'>
            <NavLink to='/'><div className='cc-back'><FaArrowLeft /></div></NavLink>
            <div className='cc-card'>
              <div className='cc-card-decor'>
                <h2>{title}</h2>
                <div>
                    <span>{textSecondary}</span>
                </div>
              </div>
            </div>
            </div>
        </div>
        <div className='cc-content'>
        <h2 className='cc-title'>{title}</h2>
        <p>{textMain}</p>
        </div>
    </div>
  );
}

export default Content;
