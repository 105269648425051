import './index.sass';
import cateringBanket from '../../../public/cateing-banket.jpg'
import cateringFurshet from '../../../public/catering-furshet.jpg'
import cofee from '../../../public/cofe.jpg'
import bbq from '../../../public/bbq.jpg'
import { NavLink } from 'react-router-dom';

const cards = [
    {
        title: 'Банкет',
        description: 'Торжественное мероприятие, проводимое в честь определённого человека, не редко сопровождается банкетом.',
        image: cateringBanket,
        href: '/banket'
    },
    {
        title: 'Фуршет',
        description: 'Фуршет является одним из самых изысканных, уникальных и приемлемых форматов для различных мероприятий.',
        image: cateringFurshet,
        href: '/furshet'
    },
    {
        title: 'Кофе-брейк',
        description: 'Если вы спланировали конференцию или семинар, Вам следует продумать комфорт во время проведения мероприятия.',
        image: cofee,
        href: '/coffee-break'
    },
    {
        title: 'Барбекю',
        description: 'Кейтеринг барбекю – это настоящее искусство приготовления гастрономических шедевров на свежем воздухе.',
        image: bbq,
        href: '/bbq'
    }
]

const FormatSection = (props) => {
    return (
        <div ref={props.refer} className='section'>
            <div className='section--header_wrapper'>
                <h2 className='section--header'>ФОРМАТЫ</h2>
            </div>
            <div className='cards-holder'>
                {cards.map((card, index) => {
                    return (
                        <div className='cards-holder--card'>
                            {(index) % 2 ?
                                <img className='cards-holder--card--img' src={card.image} alt='cardImage'></img> : null
                            }
                            <div className='cards-holder--card--content'>
                                <h2 className='cards-holder--card--header'>{card.title}</h2>
                                <span className='cards-holder--card--description'>
                                    {card.description}
                                </span>
                                <NavLink className='cards-holder--card--more' to={card.href}>Подробнее</NavLink>
                            </div>
                            {(index + 1) % 2 ?
                                <img className='cards-holder--card--img' src={card.image} alt='cardImage'></img> : null
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default FormatSection;
